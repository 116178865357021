import { useState, useEffect } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonModal,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonImg,
  IonIcon,
} from "@ionic/react";
import "./Map.css";
import { pin, boat } from "ionicons/icons";

export default function Map() {
  const [mapData, setMapData] = useState([]);
  const [pinpoint, setPinpoint] = useState(0);
  const [showModal, setShowModal] = useState(false);

  // Array of class names that should not display the pin icon
  const hiddenPinIcons = [
    "potawatomi_summer_camp",
    "lassens_resort",
    "cedar_lake_station",
  ];

  useEffect(() => {
    fetch("data/data.json")
      .then((response) => response.json())
      .then((data) => {
        setMapData(data);
      });
  }, [pinpoint]);

  const pinpointList = [
    { className: "lassens_resort", hoverText: "Lassen's Resort" },
    { className: "binyons_resort", hoverText: "Binyon's Resort" },
    { className: "toomeys_park", hoverText: "Toomey's Park" },
    { className: "shamrock_inn", hoverText: "Shamrock Inn" },
    { className: "sans_souci_hotel", hoverText: "Sans Souci Hotel" },
    { className: "olsen_hotel", hoverText: "Olsen Hotel" },
    { className: "surprise_park", hoverText: "Surprise Park" },
    { className: "shady_beach", hoverText: "Shady Beach" },
    { className: "monon_park", hoverText: "Monon Park" },
    { className: "webber_paisley_hotel", hoverText: "Webber Paisley Hotel" },
    { className: "breuill_hotel", hoverText: "Breuill Hotel" },
    { className: "john_mitch_hotel", hoverText: "John Mitch Hotel" },
    { className: "big3_inn", hoverText: "Big 3 Inn" },
    { className: "cedar_lake_station", hoverText: "Cedar Lake Station" },
    { className: "sigler_monon", hoverText: "Sigler Monon" },
    { className: "gledenning_hotel", hoverText: "Gledenning Hotel" },
    { className: "hunter_hotel", hoverText: "Hunter Hotel" },
    { className: "von_borstel_hotel", hoverText: "Von Borstel Hotel" },
    { className: "terrace_garden_beach", hoverText: "Terrace Garden Beach" },
    { className: "lakeview_hotel", hoverText: "Lakeview Hotel" },
    { className: "bartells_resort", hoverText: "Bartell's Resort" },
    { className: "victors_beach", hoverText: "Victor's Beach" },
    { className: "iron_lantern_inn", hoverText: "Iron Lantern Inn" },
    { className: "youngs_hotel", hoverText: "Young's Hotel" },
    { className: "huntsmans_lodge", hoverText: "Huntsman's Lodge" },
    { className: "ellerys_inn", hoverText: "Ellery's Inn" },
    { className: "top_flight_hotel", hoverText: "Top Flight Hotel" },
    { className: "sunset_hotel", hoverText: "Sunset Hotel" },
    { className: "midway_gardens", hoverText: "Midway Gardens" },
    { className: "derby_hotel", hoverText: "Derby Hotel" },
    { className: "burke_hotel", hoverText: "Burke Hotel" },
    { className: "kennedy_hotel", hoverText: "Kennedy Hotel" },
    { className: "cedar_point_hotel", hoverText: "Cedar Point Hotel" },
    { className: "stanleys_hotel", hoverText: "Stanley's Hotel" },
    {
      className: "potawatomi_summer_camp",
      hoverText: "Potawatomi Summer Camp",
    },
    { className: "yacht_club", hoverText: "Cedar Lake Yacht Club" },
    { className: "pleasant_valley", hoverText: "Pleasant Valley" },
    { className: "gerbings", hoverText: "Gerbing's" },
    { className: "colemans_hotel", hoverText: "Coleman's Hotel" },
    { className: "cedar_beach_hotel", hoverText: "Cedar Beach Hotel" },
    { className: "john_adams_hotel", hoverText: "John Adams Hotel" },
  ];

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Resorts of Cedar Lake</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="mapPinpoints">
          <IonImg
            src="/assets/CLHA/Resort_Map_wall-min.jpg"
            className="mapImg"
          ></IonImg>

          {pinpointList.map((pinpoint, index) => (
            <div
              className={`pinpoint ${pinpoint.className}`}
              onClick={() => {
                setPinpoint(index);
                setShowModal(true);
              }}
              key={index}
              title={pinpoint.hoverText}
            >
              {!hiddenPinIcons.includes(pinpoint.className) && (
                <IonIcon
                  icon={pinpoint.className === "yacht_club" ? boat : pin}
                  className={`pinIcon ${
                    pinpoint.className === "midway_gardens" ? "yellowPin" : ""
                  }`}
                />
              )}
            </div>
          ))}
        </div>

        {mapData.length > 1 && (
          <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
            <IonContent>
              <IonCard>
                <IonCardHeader>
                  <IonButton
                    className="modal_button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </IonButton>
                  <IonCardSubtitle className="modal_subtitle">
                    {mapData[pinpoint].time}
                  </IonCardSubtitle>
                  <IonCardTitle className="modal_title">
                    {mapData[pinpoint].name}
                  </IonCardTitle>
                </IonCardHeader>
                {mapData[pinpoint].description.map((paragraph) => (
                  <IonCardContent>{paragraph}</IonCardContent>
                ))}
                {mapData[pinpoint].images.map((image) => (
                  <IonImg className="modalImage" src={image}></IonImg>
                ))}
              </IonCard>
            </IonContent>
          </IonModal>
        )}
      </IonContent>
    </IonPage>
  );
}
